export const missionData = [
    { id: 1, name: 'Any Mission' },
    { id: 2, name: 'Righteous Stand' },
    { id: 3, name: 'Convocation of Decay' },
    { id: 4, name: 'Hunger in the Dark' },
    { id: 5, name: 'Halescourge' },
    { id: 6, name: 'Athel Yenlui' },
    { id: 7, name: 'The Screaming Bell' },
    { id: 8, name: 'Fort Brachsenbrücke' },
    { id: 9, name: 'Into the Nest' },
    { id: 10, name: 'Against the Grain' },
    { id: 11, name: 'Empire in Flames' },
    { id: 12, name: 'Festering Ground' },
    { id: 13, name: 'The War Camp' },
    { id: 14, name: 'The Skittergate' },
    { id: 15, name: 'The Pit' },
    { id: 16, name: 'The Blightreaper' },
    { id: 17, name: 'The Horn of Magnus' },
    { id: 18, name: 'Garden of Morr' },
    { id: 19, name: 'Engines of War' },
    { id: 20, name: 'Fortunes of War' },
    { id: 21, name: 'Dark Omens' },
    { id: 22, name: 'Old Haunts' },
    { id: 23, name: 'Blood in the Darkness' },
    { id: 24, name: 'The Enchanter\'s Lair' },
    { id: 25, name: 'Trail of Treachery' },
    { id: 26, name: 'Tower of Treachery' },
    { id: 27, name: 'Mission of Mercy' },
    { id: 28, name: 'A Grudge Served Cold' },
    { id: 29, name: 'Khazukan Kazakit-ha!' }
];
