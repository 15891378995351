export const PRIMARY_PROPERTY1_INDEX = 0;
export const PRIMARY_PROPERTY2_INDEX = 1;
export const PRIMARY_TRAIT_INDEX = 0;
export const SECONDARY_PROPERTY1_INDEX = 2;
export const SECONDARY_PROPERTY2_INDEX = 3;
export const SECONDARY_TRAIT_INDEX = 1;
export const NECKLACE_PROPERTY1_INDEX = 4;
export const NECKLACE_PROPERTY2_INDEX =5;
export const NECKLACE_TRAIT_INDEX = 2;
export const CHARM_PROPERTY1_INDEX = 6;
export const CHARM_PROPERTY2_INDEX = 7;
export const CHARM_TRAIT_INDEX = 3;
export const TRINKET_PROPERTY1_INDEX = 8;
export const TRINKET_PROPERTY2_INDEX = 9;
export const TRINKET_TRAIT_INDEX = 4;
export const TALENT_TIER_1 = 0;
export const TALENT_TIER_2 = 1;
export const TALENT_TIER_3 = 2;
export const TALENT_TIER_4 = 3;
export const TALENT_TIER_5 = 4;
export const TALENT_TIER_6 = 5;