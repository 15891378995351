export const weaponsData = {
		"dr_1h_hammer": {
			"name": "Hammer", 
			"flavorText": "Hammer wielded by an inexperienced warrior, possibly on his first battle away from the hold.", 
			"description": "weapon_keyword_fast_attacks, weapon_keyword_wide_sweeps, weapon_keyword_crowd_control", 
			"tooltipLocalized": "Fast Attacks, Wide Sweeps, Crowd Control", 
			"dodgeDistance": "1.2", 
			"dodgeSpeed": "1.2", 
			"dodgeCount": "3", 
			"stamina": "9", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.80", 
			"canWieldPrimary": ["dr_ironbreaker", "dr_slayer", "dr_ranger", "dr_engineer"], 
			"canWieldSecondary": ["dr_slayer"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"we_dual_wield_sword_dagger": {
			"name": "Sword and Dagger", 
			"flavorText": "Many asrai learn the first steps of battle in ritualised duel, parrying with a leaf-bladed dagger and riposting with a honed longsword.", 
			"description": "weapon_keyword_fast_attacks, weapon_keyword_wide_sweeps, weapon_keyword_versatile", 
			"tooltipLocalized": "Fast Attacks, Wide Sweeps, Versatile", 
			"dodgeDistance": "1.25", 
			"dodgeSpeed": "1.25", 
			"dodgeCount": "6", 
			"stamina": "6", 
			"blockInnerCost": "0.75", 
			"blockOuterCost": "1.5", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.85", 
			"canWieldPrimary": ["we_shade", "we_maidenguard", "we_waywatcher", "we_thornsister"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"we_dual_wield_daggers": {
			"name": "Dual Daggers", 
			"flavorText": "Matched daggers, easily concealed about one’s person. After all, is not the world beyond the boughs a wicked realm of treachery and misdeed? An elf must be prepared to defend herself, even before provocation lands.", 
			"description": "weapon_keyword_fast_attacks, weapon_keyword_headshotting, weapon_keyword_damage_over_time", 
			"tooltipLocalized": "Fast Attacks, Headshot Damage, Damage over Time", 
			"dodgeDistance": "1.35", 
			"dodgeSpeed": "1.35", 
			"dodgeCount": "6", 
			"stamina": "4", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "40", 
			"rightClickMovementModifier": "0.90", 
			"canWieldPrimary": ["we_shade", "we_maidenguard", "we_waywatcher", "we_thornsister"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"we_2h_axe": {
			"name": "Glaive", 
			"flavorText": "A practice glaive, untampered for the true work of battle, and carried by those who seek to train in its art.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_armour_piercing, weapon_keyword_fast_attacks", 
			"tooltipLocalized": "High Damage, Armour Piercing, Fast Attacks", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "3", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.75", 
			"canWieldPrimary": ["we_shade", "we_maidenguard", "we_waywatcher", "we_thornsister"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"wh_2h_sword": {
			"name": "Greatsword", 
			"flavorText": "Shoddy greatsword, employed only by Witch Hunters on the path of penance.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_wide_sweeps, weapon_keyword_crowd_control", 
			"tooltipLocalized": "High Damage, Wide Sweeps, Crowd Control", 
			"dodgeDistance": "1.1", 
			"dodgeSpeed": "1.1", 
			"dodgeCount": "3", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.50", 
			"canWieldPrimary": ["wh_zealot", "wh_bountyhunter", "wh_captain"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"es_handgun": {
			"name": "Handgun", 
			"flavorText": "A handful of hidden defects separate this weapon from the pinnacle of the gunsmith’s art, but it serves well enough for those in need.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_sniper, weapon_keyword_headshotting", 
			"tooltipLocalized": "High Damage, Sniper, Headshot Damage", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "nil", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.25", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["es_huntsman", "es_knight", "es_mercenary"], 
			"traitCategory": "ranged_ammo", 
			"propertyCategory": "ranged", 
		},
		"es_1h_mace": {
			"name": "Mace", 
			"flavorText": "The Empire takes whatever militia recruits it can find, regardless of ability. When such recruits cannot be trusted with a sharp edge, and poor-quality mace is provided instead.", 
			"description": "weapon_keyword_fast_attacks, weapon_keyword_wide_sweeps, weapon_keyword_crowd_control", 
			"tooltipLocalized": "Fast Attacks, Wide Sweeps, Crowd Control", 
			"dodgeDistance": "1.2", 
			"dodgeSpeed": "1.2", 
			"dodgeCount": "3", 
			"stamina": "9", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.80", 
			"canWieldPrimary": ["es_huntsman", "es_knight", "es_mercenary", "es_questingknight"], 
			"canWieldSecondary": ["es_questingknight"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"bw_skullstaff_beam": {
			"name": "Beam Staff", 
			"flavorText": "This staff leaks power, the result of damage that no amount of reforging will repair.", 
			"description": "weapon_keyword_sniper, weapon_keyword_crowd_control, weapon_keyword_damage_over_time", 
			"tooltipLocalized": "Sniper, Crowd Control, Damage over Time", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "nil", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.90", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["bw_scholar", "bw_adept", "bw_unchained", "bw_necro"], 
			"traitCategory": "ranged_heat", 
			"propertyCategory": "ranged", 
		},
		"bw_1h_crowbill": {
			"name": "Crowbill", 
			"flavorText": "A ritual weapon of the Bright Order, often pressed to service by wizards who prefer to see their foes up close.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_damage_over_time, weapon_keyword_wide_sweeps", 
			"tooltipLocalized": "High Damage, Damage over Time, Wide Sweeps", 
			"dodgeDistance": "1.25", 
			"dodgeSpeed": "1.25", 
			"dodgeCount": "3", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.80", 
			"canWieldPrimary": ["bw_scholar", "bw_adept", "bw_unchained", "bw_necro"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"es_1h_sword": {
			"name": "Sword", 
			"flavorText": "A sword of the poorest quality, given to wet-behind-the-ears farmhands and new recruits. The pointy end goes in the enemy.", 
			"description": "weapon_keyword_fast_attacks, weapon_keyword_wide_sweeps, weapon_keyword_versatile", 
			"tooltipLocalized": "Fast Attacks, Wide Sweeps, Versatile", 
			"dodgeDistance": "1.2", 
			"dodgeSpeed": "1.2", 
			"dodgeCount": "3", 
			"stamina": "8", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.85", 
			"canWieldPrimary": ["es_huntsman", "es_knight", "es_mercenary", "es_questingknight"], 
			"canWieldSecondary": ["es_questingknight"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"wh_crossbow": {
			"name": "Crossbow", 
			"flavorText": "It’s a close thing as to whether the wielder would be better served simply hurling this weapon at the foe.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_sniper, weapon_keyword_piercing_bolts", 
			"tooltipLocalized": "High Damage, Sniper, Piercing Bolts", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "1", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.25", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["wh_zealot", "wh_bountyhunter", "wh_captain"], 
			"traitCategory": "ranged_ammo", 
			"propertyCategory": "ranged", 
		},
		"es_2h_sword_executioner": {
			"name": "Executioner Sword", 
			"flavorText": "A two-handed sword forged for dealing broad, decapitating strikes. The weapon’s lighter-than-average blade suggests it was borne by a newly-trained soldier, not yet strong enough to wield a true executioner’s sword.", 
			"description": "weapon_keyword_headshotting, weapon_keyword_wide_sweeps, weapon_keyword_high_damage", 
			"tooltipLocalized": "Headshot Damage, Wide Sweeps, High Damage", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "2", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.50", 
			"canWieldPrimary": ["es_huntsman", "es_knight", "es_mercenary", "es_questingknight"], 
			"canWieldSecondary": ["es_questingknight"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"dr_handgun": {
			"name": "Handgun", 
			"flavorText": "Handgun fashioned by an engineer fonder of Bugman’s XXXXX than an honest day’s work.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_sniper, weapon_keyword_headshotting", 
			"tooltipLocalized": "High Damage, Sniper, Headshot Damage", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "nil", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.25", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["dr_ironbreaker", "dr_ranger", "dr_engineer"], 
			"traitCategory": "ranged_ammo", 
			"propertyCategory": "ranged", 
		},
		"dr_2h_pick": {
			"name": "War Pick", 
			"flavorText": "A miner’s pick, battered from labours far underground, but worth its weight in battle.", 
			"description": "weapon_keyword_wide_sweeps, weapon_keyword_crowd_control, weapon_keyword_heavy_charge", 
			"tooltipLocalized": "Wide Sweeps, Crowd Control, Heavy Charged Attack", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "2", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.60", 
			"canWieldPrimary": ["dr_ironbreaker", "dr_slayer", "dr_ranger", "dr_engineer"], 
			"canWieldSecondary": ["dr_slayer"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"dr_shield_axe": {
			"name": "Axe and Shield", 
			"flavorText": "An axe and shield so poorly made it is fit only for trade with the Empire.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_armour_piercing, weapon_keyword_powerful_blocks", 
			"tooltipLocalized": "High Damage, Armour Piercing, Powerful Blocks", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "2", 
			"stamina": "10", 
			"blockInnerCost": "0.2", 
			"blockOuterCost": "2", 
			"blockAngle": "180", 
			"rightClickMovementModifier": "0.60", 
			"canWieldPrimary": ["dr_ironbreaker", "dr_ranger", "dr_engineer"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"es_sword_shield": {
			"name": "Sword and Shield", 
			"flavorText": "The shield’s balance is ever-so-slightly off – not something the inexperienced would notice, but screamingly evident to a veteran.", 
			"description": "weapon_keyword_fast_attacks, weapon_keyword_wide_sweeps, weapon_keyword_powerful_blocks", 
			"tooltipLocalized": "Fast Attacks, Wide Sweeps, Powerful Blocks", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "2", 
			"stamina": "10", 
			"blockInnerCost": "0.2", 
			"blockOuterCost": "2", 
			"blockAngle": "120", 
			"rightClickMovementModifier": "0.60", 
			"canWieldPrimary": ["es_huntsman", "es_knight", "es_mercenary", "es_questingknight"], 
			"canWieldSecondary": ["es_questingknight"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"es_1h_flail": {
			"name": "Flail", 
			"flavorText": "Even a crude flail is a difficult weapon to master, and a cruel punishment best avoided.", 
			"description": "weapon_keyword_ignore_shields, weapon_keyword_wide_sweeps, weapon_keyword_crowd_control", 
			"tooltipLocalized": "Ignore Shields, Wide Sweeps, Crowd Control", 
			"dodgeDistance": "1.15", 
			"dodgeSpeed": "1.15", 
			"dodgeCount": "4", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.80", 
			"canWieldPrimary": ["wh_zealot", "wh_captain", "wh_bountyhunter"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"dr_1h_axe": {
			"name": "Axe", 
			"flavorText": "A battered training axe, fit for bashing gobbo skulls, but little else.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_armour_piercing, weapon_keyword_shield_breaking", 
			"tooltipLocalized": "High Damage, Armour Piercing, Shield Breaking", 
			"dodgeDistance": "1.2", 
			"dodgeSpeed": "1.2", 
			"dodgeCount": "6", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.80", 
			"canWieldPrimary": ["dr_ironbreaker", "dr_slayer", "dr_ranger", "dr_engineer"], 
			"canWieldSecondary": ["dr_slayer"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"dr_shield_hammer": {
			"name": "Hammer and Shield", 
			"flavorText": "Hammer and shield of poor quality. A source of shame for the craftsman who created them.", 
			"description": "weapon_keyword_wide_sweeps, weapon_keyword_crowd_control, weapon_keyword_powerful_blocks", 
			"tooltipLocalized": "Wide Sweeps, Crowd Control, Powerful Blocks", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "2", 
			"stamina": "10", 
			"blockInnerCost": "0.2", 
			"blockOuterCost": "2", 
			"blockAngle": "180", 
			"rightClickMovementModifier": "0.60", 
			"canWieldPrimary": ["dr_ironbreaker", "dr_ranger", "dr_engineer"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"bw_sword": {
			"name": "Sword", 
			"flavorText": "A crudely-forged sword, relied on by wizards who get a little too close to the foe.", 
			"description": "weapon_keyword_fast_attacks, weapon_keyword_wide_sweeps, weapon_keyword_versatile", 
			"tooltipLocalized": "Fast Attacks, Wide Sweeps, Versatile", 
			"dodgeDistance": "1.2", 
			"dodgeSpeed": "1.2", 
			"dodgeCount": "3", 
			"stamina": "8", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.85", 
			"canWieldPrimary": ["bw_scholar", "bw_adept", "bw_unchained", "bw_necro"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"wh_1h_axe": {
			"name": "Axe", 
			"flavorText": "This inexpensive weapon would be better served splitting lumber than skulls, but might yet serve as a worthy weapon in righteous hands.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_armour_piercing, weapon_keyword_shield_breaking", 
			"tooltipLocalized": "High Damage, Armour Piercing, Shield Breaking", 
			"dodgeDistance": "1.2", 
			"dodgeSpeed": "1.2", 
			"dodgeCount": "6", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.80", 
			"canWieldPrimary": ["wh_zealot", "wh_bountyhunter", "wh_captain"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"bw_1h_mace": {
			"name": "Mace", 
			"flavorText": "The head can be heard rattling with every strike.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_armour_piercing, weapon_keyword_wide_sweeps", 
			"tooltipLocalized": "High Damage, Armour Piercing, Wide Sweeps", 
			"dodgeDistance": "1.2", 
			"dodgeSpeed": "1.2", 
			"dodgeCount": "4", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.80", 
			"canWieldPrimary": ["bw_scholar", "bw_adept", "bw_unchained", "bw_necro"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"es_mace_shield": {
			"name": "Mace and Shield", 
			"flavorText": "Though a farmhand’s wits are ever a barrier to good soldiery, his brawn can work wonders with even the shoddiest of weapons.", 
			"description": "weapon_keyword_wide_sweeps, weapon_keyword_crowd_control, weapon_keyword_powerful_blocks", 
			"tooltipLocalized": "Wide Sweeps, Crowd Control, Powerful Blocks", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "2", 
			"stamina": "10", 
			"blockInnerCost": "0.2", 
			"blockOuterCost": "2", 
			"blockAngle": "180", 
			"rightClickMovementModifier": "0.60", 
			"canWieldPrimary": ["es_huntsman", "es_knight", "es_mercenary", "es_questingknight"], 
			"canWieldSecondary": ["es_questingknight"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"we_1h_sword": {
			"name": "Sword", 
			"flavorText": "A moonsteel sword, issued to all those who take up arms in Athel Loren’s defence.", 
			"description": "weapon_keyword_fast_attacks, weapon_keyword_wide_sweeps, weapon_keyword_versatile", 
			"tooltipLocalized": "Fast Attacks, Wide Sweeps, Versatile", 
			"dodgeDistance": "1.25", 
			"dodgeSpeed": "1.25", 
			"dodgeCount": "100", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "1.5", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.90", 
			"canWieldPrimary": ["we_shade", "we_maidenguard", "we_waywatcher", "we_thornsister"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"es_bastard_sword": {
			"name": "Bretonnian Longsword", 
			"flavorText": "A simple, sturdy sword with which a knight may prove his valour - and his worthiness to wield a more expensive blade.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_wide_sweeps, weapon_keyword_crowd_control", 
			"tooltipLocalized": "High Damage, Wide Sweeps, Crowd Control", 
			"dodgeDistance": "1.2", 
			"dodgeSpeed": "1.2", 
			"dodgeCount": "3", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.50", 
			"canWieldPrimary": ["es_huntsman", "es_knight", "es_mercenary", "es_questingknight"], 
			"canWieldSecondary": ["es_questingknight"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"es_dual_wield_hammer_sword": {
			"name": "Mace and Sword", 
			"flavorText": "It's a favourite saying in the militia that you shouldn't trust anyone or anything but the weapons at your sides. More weapons equals more trust.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_fast_attacks, weapon_keyword_crowd_control", 
			"tooltipLocalized": "High Damage, Fast Attacks, Crowd Control", 
			"dodgeDistance": "1.15", 
			"dodgeSpeed": "1.15", 
			"dodgeCount": "3", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.85", 
			"canWieldPrimary": ["es_huntsman", "es_knight", "es_mercenary", "es_questingknight"], 
			"canWieldSecondary": ["es_questingknight"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"dr_dual_wield_hammers": {
			"name": "Dual Hammers", 
			"flavorText": "What's better than a good dwarf-forged hammer? Two dwarf-forged hammers, of course! Perfect for rhythmic retribution on the grudgeworthy.", 
			"description": "weapon_keyword_crowd_control, weapon_keyword_fast_attacks, weapon_keyword_wide_sweeps", 
			"tooltipLocalized": "Crowd Control, Fast Attacks, Wide Sweeps", 
			"dodgeDistance": "1.15", 
			"dodgeSpeed": "1.15", 
			"dodgeCount": "3", 
			"stamina": "8", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.85", 
			"canWieldPrimary": ["dr_ironbreaker", "dr_slayer", "dr_ranger", "dr_engineer"], 
			"canWieldSecondary": ["dr_slayer"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"es_sword_shield_breton": {
			"name": "Bretonnian Sword and Shield", 
			"flavorText": "Sword and shield typical of the kind employed by a knight newly-come to his spurs. Durable and reliable.", 
			"description": "weapon_keyword_fast_attacks, weapon_keyword_wide_sweeps, weapon_keyword_powerful_blocks", 
			"tooltipLocalized": "Fast Attacks, Wide Sweeps, Powerful Blocks", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "4", 
			"stamina": "10", 
			"blockInnerCost": "0.2", 
			"blockOuterCost": "2", 
			"blockAngle": "120", 
			"rightClickMovementModifier": "0.60", 
			"canWieldPrimary": ["es_questingknight"], 
			"canWieldSecondary": ["es_questingknight"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"dr_deus_01": {
			"name": "Trollhammer Torpedo", 
			"flavorText": "Simple weapon, used in the forge to craft new equipment. Can be used for self-defence in a pinch.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_sniper, weapon_keyword_piercing_bolts", 
			"tooltipLocalized": "High Damage, Sniper, Piercing Bolts", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "1", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.80", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["dr_ironbreaker", "dr_engineer"], 
			"traitCategory": "ranged_ammo", 
			"propertyCategory": "ranged", 
		},
		"dr_2h_axe": {
			"name": "Great Axe", 
			"flavorText": "Great axe of acceptable quality. Still superior to most human weapons.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_shield_breaking, weapon_keyword_armour_piercing", 
			"tooltipLocalized": "High Damage, Shield Breaking, Armour Piercing", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "2", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.50", 
			"canWieldPrimary": ["dr_ironbreaker", "dr_slayer", "dr_ranger", "dr_engineer"], 
			"canWieldSecondary": ["dr_slayer"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"wh_deus_01": {
			"name": "Griffon-foot", 
			"flavorText": "Simple weapon, used in the forge to craft new equipment. Can be used for self-defence in a pinch.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_rapid_fire, weapon_keyword_versatile", 
			"tooltipLocalized": "High Damage, Rapid Fire, Versatile", 
			"dodgeDistance": "1.25", 
			"dodgeSpeed": "1.25", 
			"dodgeCount": "100", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "nil", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["wh_bountyhunter", "wh_captain", "wh_zealot"], 
			"traitCategory": "ranged_ammo", 
			"propertyCategory": "ranged", 
		},
		"we_deus_01": {
			"name": "Moonfire Bow", 
			"flavorText": "Simple weapon, used in the forge to craft new equipment. Can be used for self-defence in a pinch.", 
			"description": "weapon_keyword_armour_piercing, weapon_keyword_sniper, weapon_keyword_versatile", 
			"tooltipLocalized": "Armour Piercing, Sniper, Versatile", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "3", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.25", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["we_shade", "we_maidenguard", "we_waywatcher", "we_thornsister"], 
			"traitCategory": "ranged_energy", 
			"propertyCategory": "ranged", 
		},
		"bw_skullstaff_flamethrower": {
			"name": "Flamestorm Staff", 
			"flavorText": "This staff's smoky aroma speaks ill of the previous wielder's fate.", 
			"description": "weapon_keyword_damage_over_time, weapon_keyword_charged_attack, weapon_keyword_close_range", 
			"tooltipLocalized": "Damage over Time, Charged Attack, Close Range", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "nil", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.90", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["bw_scholar", "bw_adept", "bw_unchained"], 
			"traitCategory": "ranged_heat", 
			"propertyCategory": "ranged", 
		},
		"es_deus_01": {
			"name": "Spear and Shield", 
			"flavorText": "Simple weapon, used in the forge to craft new equipment. Can be used for self-defence in a pinch.", 
			"description": "weapon_keyword_fast_attacks, weapon_keyword_wide_sweeps, weapon_keyword_powerful_blocks", 
			"tooltipLocalized": "Fast Attacks, Wide Sweeps, Powerful Blocks", 
			"dodgeDistance": "1.05", 
			"dodgeSpeed": "1.05", 
			"dodgeCount": "3", 
			"stamina": "10", 
			"blockInnerCost": "0.2", 
			"blockOuterCost": "2", 
			"blockAngle": "120", 
			"rightClickMovementModifier": "0.60", 
			"canWieldPrimary": ["es_huntsman", "es_knight", "es_mercenary"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"es_longbow": {
			"name": "Longbow", 
			"flavorText": "Simple but effective longbow design, commonly seen amongst Reikland archer regiments.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_sniper, weapon_keyword_armour_piercing", 
			"tooltipLocalized": "High Damage, Sniper, Armour Piercing", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "3", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.25", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["es_huntsman"], 
			"traitCategory": "ranged_ammo", 
			"propertyCategory": "ranged", 
		},
		"bw_deus_01": {
			"name": "Coruscation Staff", 
			"flavorText": "Simple weapon, used in the forge to craft new equipment. Can be used for self-defence in a pinch.", 
			"description": "weapon_keyword_damage_over_time, weapon_keyword_crowd_control, weapon_keyword_charged_attack", 
			"tooltipLocalized": "Damage over Time, Crowd Control, Charged Attack", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "nil", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.10", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["bw_scholar", "bw_adept", "bw_unchained", "bw_necro"], 
			"traitCategory": "ranged_heat", 
			"propertyCategory": "ranged", 
		},
		"es_repeating_handgun": {
			"name": "Repeater Handgun", 
			"flavorText": "A basic model of ‘Von Meinkopt's Whirling Cavalcade of Death’, capable of a prodigious rate of fire.", 
			"description": "weapon_keyword_rapid_fire, weapon_keyword_armour_piercing, weapon_keyword_versatile", 
			"tooltipLocalized": "Rapid Fire, Armour Piercing, Versatile", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "1", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.40", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["es_huntsman", "es_knight", "es_mercenary"], 
			"traitCategory": "ranged_ammo", 
			"propertyCategory": "ranged", 
		},
		"dr_2h_cog_hammer": {
			"name": "Cog Hammer", 
			"flavorText": "Hammer fashioned by an Engineer's apprentice. Good for breaking skulls and fixing unhappy machinery in equal measure.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_shield_breaking, weapon_keyword_armour_piercing", 
			"tooltipLocalized": "High Damage, Shield Breaking, Armour Piercing", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "2", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.50", 
			"canWieldPrimary": ["dr_engineer", "dr_ranger", "dr_ironbreaker", "dr_slayer"], 
			"canWieldSecondary": ["dr_slayer"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"we_life_staff": {
			"name": "Deepwood Staff", 
			"flavorText": "A simple staff, woven from ever-oak and employed to channel the blessings of Athel Loren.", 
			"description": "weapon_keyword_sniper, weapon_keyword_crowd_control, weapon_keyword_damage_over_time", 
			"tooltipLocalized": "Sniper, Crowd Control, Damage over Time", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "nil", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "nil", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["we_thornsister"], 
			"traitCategory": "ranged_heat", 
			"propertyCategory": "ranged", 
		},
		"wh_2h_billhook": {
			"name": "Bill Hook", 
			"flavorText": "It's a long-running joke in the Empire that Stirlanders are only fractionally brighter than the neighbouring halflings. Whether this mis-named blade is a genuine mistake - or an outsider's joke - is impossible to know.", 
			"description": "weapon_keyword_wide_sweeps, weapon_keyword_armour_piercing, weapon_keyword_versatile", 
			"tooltipLocalized": "Wide Sweeps, Armour Piercing, Versatile", 
			"dodgeDistance": "1.15", 
			"dodgeSpeed": "1.15", 
			"dodgeCount": "3", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.60", 
			"canWieldPrimary": ["wh_zealot", "wh_bountyhunter", "wh_captain"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"we_1h_spears_shield": {
			"name": "Spear and Shield", 
			"flavorText": "Tiranoci elves much prefer to do battle from chariots. On the rare occasion one plants a shield in the turf, it's sure sign that she intends not to give an inch of ground.", 
			"description": "weapon_keyword_fast_attacks, weapon_keyword_wide_sweeps, weapon_keyword_powerful_blocks", 
			"tooltipLocalized": "Fast Attacks, Wide Sweeps, Powerful Blocks", 
			"dodgeDistance": "1.05", 
			"dodgeSpeed": "1.05", 
			"dodgeCount": "3", 
			"stamina": "10", 
			"blockInnerCost": "0.2", 
			"blockOuterCost": "2", 
			"blockAngle": "120", 
			"rightClickMovementModifier": "0.60", 
			"canWieldPrimary": ["we_maidenguard"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"es_2h_heavy_spear": {
			"name": "Tuskgor Spear", 
			"flavorText": "Trusty spear borne by those who strike out into the wild. Equally good for skewering goblins, beastmen, and - when circumstance requires - cooking the remains to sell to halflings.", 
			"description": "weapon_keyword_fast_attacks, weapon_keyword_versatile, weapon_keyword_high_agility", 
			"tooltipLocalized": "Fast Attacks, Versatile, High Mobility", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "3", 
			"stamina": "8", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "180", 
			"rightClickMovementModifier": "0.80", 
			"canWieldPrimary": ["es_huntsman", "es_mercenary"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"we_shortbow_hagbane": {
			"name": "Hagbane Shortbow", 
			"flavorText": "An asrai bow crafted to the shooting of poisoned shot – though this one has seen better days.", 
			"description": "weapon_keyword_damage_over_time, weapon_keyword_rapid_fire, weapon_keyword_crowd_control", 
			"tooltipLocalized": "Damage over Time, Rapid Fire, Crowd Control", 
			"dodgeDistance": "1.25", 
			"dodgeSpeed": "1.25", 
			"dodgeCount": "6", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.80", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["we_shade", "we_maidenguard", "we_waywatcher", "we_thornsister"], 
			"traitCategory": "ranged_ammo", 
			"propertyCategory": "ranged", 
		},
		"dr_1h_throwing_axes": {
			"name": "Throwing Axes", 
			"flavorText": "An early prototype, forged by a newcomer to the craft. Still leaves a nasty mark when it flies true.", 
			"description": "weapon_keyword_armour_piercing, weapon_keyword_sniper, weapon_keyword_versatile", 
			"tooltipLocalized": "Armour Piercing, Sniper, Versatile", 
			"dodgeDistance": "1.2", 
			"dodgeSpeed": "1.2", 
			"dodgeCount": "6", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.25", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["dr_slayer", "dr_ranger"], 
			"traitCategory": "ranged_ammo", 
			"propertyCategory": "ranged", 
		},
		"bw_skullstaff_fireball": {
			"name": "Fireball Staff", 
			"flavorText": "The staff is warm to the touch, but its magic flows reluctantly.", 
			"description": "weapon_keyword_crowd_control, weapon_keyword_damage_over_time, weapon_keyword_overheat", 
			"tooltipLocalized": "Crowd Control, Damage over Time, Overheat", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "nil", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "nil", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["bw_scholar", "bw_adept", "bw_unchained", "bw_necro"], 
			"traitCategory": "ranged_heat", 
			"propertyCategory": "ranged", 
		},
		"we_spear": {
			"name": "Elven Spear", 
			"flavorText": "A practice spear, commonly wielded by those who have barely begun upon the warrior’s path.", 
			"description": "weapon_keyword_fast_attacks, weapon_keyword_versatile, weapon_keyword_high_agility", 
			"tooltipLocalized": "Fast Attacks, Versatile, High Mobility", 
			"dodgeDistance": "1.15", 
			"dodgeSpeed": "1.15", 
			"dodgeCount": "3", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "180", 
			"rightClickMovementModifier": "0.80", 
			"canWieldPrimary": ["we_shade", "we_maidenguard", "we_waywatcher", "we_thornsister"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"wh_1h_falchion": {
			"name": "Falchion", 
			"flavorText": "Generally speaking, Witch Hunters have little patience with foreign weaponry. The falchion is a noted exception, chiefly for its utility in flensing the unrighteous.", 
			"description": "weapon_keyword_headshotting, weapon_keyword_high_damage, weapon_keyword_versatile", 
			"tooltipLocalized": "Headshot Damage, High Damage, Versatile", 
			"dodgeDistance": "1.25", 
			"dodgeSpeed": "1.25", 
			"dodgeCount": "4", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.75", 
			"canWieldPrimary": ["wh_zealot", "wh_bountyhunter", "wh_captain"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"wh_dual_wield_axe_falchion": {
			"name": "Axe and Falchion", 
			"flavorText": "While some Witch Hunters hold ambidexterity to be proof positive of unclean obsession, most are grimly delighted to kill heretics all the faster.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_fast_attacks, weapon_keyword_armour_piercing", 
			"tooltipLocalized": "High Damage, Fast Attacks, Armour Piercing", 
			"dodgeDistance": "1.2", 
			"dodgeSpeed": "1.2", 
			"dodgeCount": "4", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.85", 
			"canWieldPrimary": ["wh_zealot", "wh_bountyhunter", "wh_captain"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"dr_dual_wield_axes": {
			"name": "Dual Axes", 
			"flavorText": "A young warrior’s first axes, likely carried to war by generations of ancestors.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_fast_attacks, weapon_keyword_armour_piercing", 
			"tooltipLocalized": "High Damage, Fast Attacks, Armour Piercing", 
			"dodgeDistance": "1.25", 
			"dodgeSpeed": "1.25", 
			"dodgeCount": "6", 
			"stamina": "4", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.85", 
			"canWieldPrimary": ["dr_slayer"], 
			"canWieldSecondary": ["dr_slayer"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"bw_flame_sword": {
			"name": "Fire Sword", 
			"flavorText": "A sword blessed with a fickle connection to Aqshy, the Fire Wind.", 
			"description": "weapon_keyword_wide_sweeps, weapon_keyword_crowd_control, weapon_keyword_damage_over_time", 
			"tooltipLocalized": "Wide Sweeps, Crowd Control, Damage over Time", 
			"dodgeDistance": "1.2", 
			"dodgeSpeed": "1.2", 
			"dodgeCount": "3", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.80", 
			"canWieldPrimary": ["bw_scholar", "bw_adept", "bw_unchained", "bw_necro"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"wh_crossbow_repeater": {
			"name": "Volley Crossbow", 
			"flavorText": "The prized tool of a Witch Hunter in a hurry.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_piercing_bolts, weapon_keyword_rapid_fire", 
			"tooltipLocalized": "High Damage, Piercing Bolts, Rapid Fire", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "3", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.25", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["wh_zealot", "wh_bountyhunter", "wh_captain"], 
			"traitCategory": "ranged_ammo", 
			"propertyCategory": "ranged", 
		},
		"bw_1h_flail_flaming": {
			"name": "Flaming Flail", 
			"flavorText": "Dressad von Meir, hero of the Thousand Days of Battle, met an unfortunate end when he clutched the flail upside down, and without benefit of gloves. The name has stuck ever since - much as the metal fused itself to Dressad's hands.", 
			"description": "weapon_keyword_ignore_shields, weapon_keyword_wide_sweeps, weapon_keyword_crowd_control", 
			"tooltipLocalized": "Ignore Shields, Wide Sweeps, Crowd Control", 
			"dodgeDistance": "1.15", 
			"dodgeSpeed": "1.15", 
			"dodgeCount": "4", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.80", 
			"canWieldPrimary": ["bw_scholar", "bw_adept", "bw_unchained", "bw_necro"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"bw_skullstaff_spear": {
			"name": "Bolt Staff", 
			"flavorText": "This staff once served as the focus of flame-wreathed ritual. Alas, those days are long behind.", 
			"description": "weapon_keyword_rapid_fire, weapon_keyword_headshotting, weapon_keyword_sniper", 
			"tooltipLocalized": "Rapid Fire, Headshot Damage, Sniper", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "nil", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.25", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["bw_scholar", "bw_adept", "bw_unchained", "bw_necro"], 
			"traitCategory": "ranged_heat", 
			"propertyCategory": "ranged", 
		},
		"es_halberd": {
			"name": "Halberd", 
			"flavorText": "Giving a halberd to a new recruit is a dangerous business, and best achieved by ensuring the blade is suitably blunted.", 
			"description": "weapon_keyword_wide_sweeps, weapon_keyword_armour_piercing, weapon_keyword_versatile", 
			"tooltipLocalized": "Wide Sweeps, Armour Piercing, Versatile", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "3", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.60", 
			"canWieldPrimary": ["es_huntsman", "es_knight", "es_mercenary"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"wh_repeating_pistols": {
			"name": "Repeater Pistol", 
			"flavorText": "Not every engineer’s tinkering can meet with success. This woeful pistol serves as reinforcement of that truth.", 
			"description": "weapon_keyword_rapid_fire, weapon_keyword_close_range, weapon_keyword_high_damage", 
			"tooltipLocalized": "Rapid Fire, Close Range, High Damage", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "3", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.40", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["wh_zealot", "wh_bountyhunter", "wh_captain"], 
			"traitCategory": "ranged_ammo", 
			"propertyCategory": "ranged", 
		},
		"dr_crossbow": {
			"name": "Crossbow", 
			"flavorText": "Crossbow fashioned by an apprentice to the Engineer’s Guild. Could be better.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_sniper, weapon_keyword_piercing_bolts", 
			"tooltipLocalized": "High Damage, Sniper, Piercing Bolts", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "1", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.25", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["dr_ironbreaker", "dr_ranger"], 
			"traitCategory": "ranged_ammo", 
			"propertyCategory": "ranged", 
		},
		"wh_brace_of_pistols": {
			"name": "Brace of Pistols", 
			"flavorText": "It’s a wonder these pistols don’t explode when fired, so fouled are their barrels.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_rapid_fire, weapon_keyword_versatile", 
			"tooltipLocalized": "High Damage, Rapid Fire, Versatile", 
			"dodgeDistance": "1.25", 
			"dodgeSpeed": "1.25", 
			"dodgeCount": "100", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.85", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["wh_zealot", "wh_bountyhunter", "wh_captain"], 
			"traitCategory": "ranged_ammo", 
			"propertyCategory": "ranged", 
		},
		"bw_skullstaff_geiser": {
			"name": "Conflagration Staff", 
			"flavorText": "A staff forged for ritual display, but capable of being pressed to combat.", 
			"description": "weapon_keyword_damage_over_time, weapon_keyword_crowd_control, weapon_keyword_charged_attack", 
			"tooltipLocalized": "Damage over Time, Crowd Control, Charged Attack", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "nil", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.10", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["bw_scholar", "bw_adept", "bw_unchained", "bw_necro"], 
			"traitCategory": "ranged_heat", 
			"propertyCategory": "ranged", 
		},
		"we_crossbow_repeater": {
			"name": "Volley Crossbow", 
			"flavorText": "A basic example of a druchii repeater, emphasising cruelty of form over function.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_rapid_fire, weapon_keyword_versatile", 
			"tooltipLocalized": "High Damage, Rapid Fire, Versatile", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "nil", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.90", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["we_shade"], 
			"traitCategory": "ranged_ammo", 
			"propertyCategory": "ranged", 
		},
		"dr_drakegun": {
			"name": "Drakegun", 
			"flavorText": "A sub-par drakegun, scorned by experienced warriors but gladly embraced by the unblooded.", 
			"description": "weapon_keyword_crowd_control, weapon_keyword_close_range, weapon_keyword_overheat", 
			"tooltipLocalized": "Crowd Control, Close Range, Overheat", 
			"dodgeDistance": "0.85", 
			"dodgeSpeed": "0.85", 
			"dodgeCount": "1", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.90", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["dr_ironbreaker", "dr_engineer"], 
			"traitCategory": "ranged_heat", 
			"propertyCategory": "ranged", 
		},
		"we_javelin": {
			"name": "Briar Javelin", 
			"flavorText": "Javelin crafted from ever-oak, and balanced for throwing. Lethal, in the right hands.", 
			"description": "weapon_keyword_armour_piercing, weapon_keyword_sniper, weapon_keyword_versatile", 
			"tooltipLocalized": "Armour Piercing, Sniper, Versatile", 
			"dodgeDistance": "1.2", 
			"dodgeSpeed": "1.2", 
			"dodgeCount": "6", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.25", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["we_thornsister", "we_shade", "we_maidenguard", "we_waywatcher"], 
			"traitCategory": "ranged_ammo", 
			"propertyCategory": "ranged", 
		},
		"es_2h_sword": {
			"name": "Greatsword", 
			"flavorText": "A misforged greatsword, likely granted to a new recruit that he might practice the arts of battle.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_wide_sweeps, weapon_keyword_crowd_control", 
			"tooltipLocalized": "High Damage, Wide Sweeps, Crowd Control", 
			"dodgeDistance": "1.1", 
			"dodgeSpeed": "1.1", 
			"dodgeCount": "3", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.50", 
			"canWieldPrimary": ["es_huntsman", "es_knight", "es_mercenary", "es_questingknight"], 
			"canWieldSecondary": ["es_questingknight"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"bw_dagger": {
			"name": "Dagger", 
			"flavorText": "Dagger gifted to Giovanni Marmalodi as part-payment for services rendered. Deep distrust of magic led to the mercenary captain never wielding the weapon – he instead kept it on his desk, as trophy and occasional paperweight.", 
			"description": "weapon_keyword_damage_over_time, weapon_keyword_crowd_control, weapon_keyword_fast_attacks", 
			"tooltipLocalized": "Damage over Time, Crowd Control, Fast Attacks", 
			"dodgeDistance": "1.2", 
			"dodgeSpeed": "1.2", 
			"dodgeCount": "6", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "40", 
			"rightClickMovementModifier": "0.85", 
			"canWieldPrimary": ["bw_scholar", "bw_adept", "bw_unchained", "bw_necro"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"es_2h_hammer": {
			"name": "Two-Handed Hammer", 
			"flavorText": "More suitable perhaps for siting fence posts than defending the Empire, this warhammer can still serve as a training tool for those new to the warrior’s art.", 
			"description": "weapon_keyword_wide_sweeps, weapon_keyword_crowd_control, weapon_keyword_armour_piercing", 
			"tooltipLocalized": "Wide Sweeps, Crowd Control, Armour Piercing", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "2", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.60", 
			"canWieldPrimary": ["es_huntsman", "es_knight", "es_mercenary", "es_questingknight"], 
			"canWieldSecondary": ["es_questingknight"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"we_shortbow": {
			"name": "Swift Bow", 
			"flavorText": "A short-strung practice bow, seldom seen in battle save from last resort.", 
			"description": "weapon_keyword_rapid_fire, weapon_keyword_crowd_control, weapon_keyword_headshotting", 
			"tooltipLocalized": "Rapid Fire, Crowd Control, Headshot Damage", 
			"dodgeDistance": "1.25", 
			"dodgeSpeed": "1.25", 
			"dodgeCount": "6", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "1.00", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["we_shade", "we_maidenguard", "we_waywatcher", "we_thornsister"], 
			"traitCategory": "ranged_ammo", 
			"propertyCategory": "ranged", 
		},
		"dr_drake_pistol": {
			"name": "Drakefire Pistols", 
			"flavorText": "Twin drake pistols carried by a young warrior. Likely a gift from a wealthy relative.", 
			"description": "weapon_keyword_overheat, weapon_keyword_crowd_control, weapon_keyword_damage_over_time", 
			"tooltipLocalized": "Overheat, Crowd Control, Damage over Time", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "2", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.75", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["dr_ironbreaker", "dr_engineer"], 
			"traitCategory": "ranged_heat", 
			"propertyCategory": "ranged", 
		},
		"dr_steam_pistol": {
			"name": "Masterwork Pistol", 
			"flavorText": "Apprentice's pistol. Though but a lowly example of the craft, is vastly superior to anything made by umgi hands.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_rapid_fire, weapon_keyword_versatile", 
			"tooltipLocalized": "High Damage, Rapid Fire, Versatile", 
			"dodgeDistance": "1.25", 
			"dodgeSpeed": "1.25", 
			"dodgeCount": "100", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.85", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["dr_engineer", "dr_ranger", "dr_ironbreaker"], 
			"traitCategory": "ranged_ammo", 
			"propertyCategory": "ranged", 
		},
		"we_longbow": {
			"name": "Longbow", 
			"flavorText": "The signature weapon of an asrai – a longbow for which human huntsmen would kill, but more often die in an attempt to attain.", 
			"description": "weapon_keyword_armour_piercing, weapon_keyword_sniper, weapon_keyword_versatile", 
			"tooltipLocalized": "Armour Piercing, Sniper, Versatile", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "3", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.25", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["we_shade", "we_maidenguard", "we_waywatcher", "we_thornsister"], 
			"traitCategory": "ranged_ammo", 
			"propertyCategory": "ranged", 
		},
		"es_blunderbuss": {
			"name": "Blunderbuss", 
			"flavorText": "A scrap cannon of prodigious size and lamentable accuracy. Appropriate for close encounters, and those who favour loud noises over efficiency.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_crowd_control, weapon_keyword_close_range", 
			"tooltipLocalized": "High Damage, Crowd Control, Close Range", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "3", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "nil", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["es_huntsman", "es_knight", "es_mercenary"], 
			"traitCategory": "ranged_ammo", 
			"propertyCategory": "ranged", 
		},
		"dr_rakegun": {
			"name": "Grudge-Raker", 
			"flavorText": "A young dwarf’s grudge-raker. Preferred weapon of those who have not yet master the art of aiming.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_crowd_control, weapon_keyword_close_range", 
			"tooltipLocalized": "High Damage, Crowd Control, Close Range", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "3", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "nil", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["dr_ironbreaker", "dr_ranger", "dr_engineer"], 
			"traitCategory": "ranged_ammo", 
			"propertyCategory": "ranged", 
		},
		"dr_2h_hammer": {
			"name": "Great Hammer", 
			"flavorText": "A weighty hammer, lacking the refinement of a true master craftsman.", 
			"description": "weapon_keyword_wide_sweeps, weapon_keyword_crowd_control, weapon_keyword_armour_piercing", 
			"tooltipLocalized": "Wide Sweeps, Crowd Control, Armour Piercing", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "2", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.60", 
			"canWieldPrimary": ["dr_ironbreaker", "dr_slayer", "dr_ranger", "dr_engineer"], 
			"canWieldSecondary": ["dr_slayer"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"we_1h_axe": {
			"name": "Elven Axe", 
			"flavorText": "An elven half-axe, often employed by asrai when travelling light, or druchii when a full-sized draich is too cumbersome for the slaughter at hand.", 
			"description": "weapon_keyword_high_damage, weapon_keyword_armour_piercing, weapon_keyword_shield_breaking", 
			"tooltipLocalized": "High Damage, Armour Piercing, Shield Breaking", 
			"dodgeDistance": "1.2", 
			"dodgeSpeed": "1.2", 
			"dodgeCount": "100", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.80", 
			"canWieldPrimary": ["we_shade", "we_maidenguard", "we_waywatcher", "we_thornsister"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"we_dual_wield_swords": {
			"name": "Dual Swords", 
			"flavorText": "Practice blades, seldom wielded in outright battle, but superior to most Imperial swords in balance and grace.", 
			"description": "weapon_keyword_fast_attacks, weapon_keyword_wide_sweeps, weapon_keyword_crowd_control", 
			"tooltipLocalized": "Fast Attacks, Wide Sweeps, Crowd Control", 
			"dodgeDistance": "1.25", 
			"dodgeSpeed": "1.25", 
			"dodgeCount": "6", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "1", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.85", 
			"canWieldPrimary": ["we_shade", "we_maidenguard", "we_waywatcher", "we_thornsister"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"wh_fencing_sword": {
			"name": "Rapier", 
			"flavorText": "This rapier is barely suitable for a peasant, let alone a stalwart seeker of heretics.", 
			"description": "weapon_keyword_fast_attacks, weapon_keyword_headshotting, weapon_keyword_versatile", 
			"tooltipLocalized": "Fast Attacks, Headshot Damage, Versatile", 
			"dodgeDistance": "1.25", 
			"dodgeSpeed": "1.25", 
			"dodgeCount": "6", 
			"stamina": "6", 
			"blockInnerCost": "0.25", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.90", 
			"canWieldPrimary": ["wh_bountyhunter", "wh_captain", "wh_zealot"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		"we_2h_sword": {
			"name": "Two-Handed Sword", 
			"flavorText": "A greatsword whose forging far outreaches the skills of mankind, but falls some way short of asrai perfection.", 
			"description": "weapon_keyword_fast_attacks, weapon_keyword_wide_sweeps, weapon_keyword_versatile", 
			"tooltipLocalized": "Fast Attacks, Wide Sweeps, Versatile", 
			"dodgeDistance": "1.1", 
			"dodgeSpeed": "1.1", 
			"dodgeCount": "6", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.75", 
			"canWieldPrimary": ["we_shade", "we_maidenguard", "we_waywatcher", "we_thornsister"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee", 
		},
		
		"wh_1h_hammer": {
			"name": "Skull-Splitter Hammer", 
			"flavorText": "", 
			"description": "weapon_keyword_fast_attacks, weapon_keyword_wide_sweeps, weapon_crowd_control", 
			"tooltipLocalized": "Fast Attacks, Wide Sweeps, Crowd Control", 
			"dodgeDistance": "1.1", 
			"dodgeSpeed": "1.1", 
			"dodgeCount": "6", 
			"stamina": "4.5", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.75",
			"canWieldPrimary": ["wh_zealot", "wh_priest"], 
			"canWieldSecondary": ["wh_priest"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee"
		},
		"wh_2h_hammer": {
			"name": "Holy Great Hammer", 
			"flavorText": "", 
			"description": "weapon_keyword_wide_sweeps, weapon_crowd_control, weapon_keyword_armour_piercing", 
			"tooltipLocalized": "Wide Sweeps, Crowd Control, Armour Piercing", 
			"dodgeDistance": "1.1", 
			"dodgeSpeed": "1.1", 
			"dodgeCount": "6", 
			"stamina": "3", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.75", 
			"canWieldPrimary": ["wh_zealot", "wh_priest"], 
			"canWieldSecondary": ["wh_priest"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee"
		},
		"wh_hammer_shield": {
			"name": "Skull-Splitter & Shield", 
			"flavorText": "", 
			"description": "weapon_keyword_wide_sweeps, weapon_crowd_control, weapon_keyword_powerful_blocks", 
			"tooltipLocalized": "Wide Sweeps, Crowd Control, Powerful Blocks", 
			"dodgeDistance": "1.1", 
			"dodgeSpeed": "1.1", 
			"dodgeCount": "6", 
			"stamina": "5", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "180", 
			"rightClickMovementModifier": "0.75", 
			"canWieldPrimary": ["wh_priest"], 
			"canWieldSecondary": ["wh_priest"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee"
		},
		"wh_dual_hammer": {
			"name": "Paired Skull-Splitters", 
			"flavorText": "", 
			"description": "weapon_crowd_control, weapon_keyword_fast_attacks, weapon_keyword_wide_sweeps", 
			"tooltipLocalized": "Crowd Control, Fast Attacks, Wide Sweeps", 
			"dodgeDistance": "1.1", 
			"dodgeSpeed": "1.1", 
			"dodgeCount": "6", 
			"stamina": "4", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.75", 
			"canWieldPrimary": ["wh_zealot", "wh_priest"], 
			"canWieldSecondary": ["wh_priest"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee"
		},
		"wh_hammer_book": {
			"name": "Skull-Splitter & Blessed Tome", 
			"flavorText": "", 
			"description": "weapon_keyword_fast_attacks, weapon_keyword_wide_sweeps, weapon_crowd_control", 
			"tooltipLocalized": "Fast Attacks, Wide Sweeps, Crowd Control", 
			"dodgeDistance": "1.1", 
			"dodgeSpeed": "1.1", 
			"dodgeCount": "6", 
			"stamina": "3", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.75", 
			"canWieldPrimary": ["wh_priest"], 
			"canWieldSecondary": ["wh_priest"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee"
		},
		"wh_flail_shield": {
			"name": "Flail & Shield", 
			"flavorText": "", 
			"description": "weapon_keyword_ignore_shields, weapon_keyword_wide_sweeps, weapon_keyword_crowd_control", 
			"tooltipLocalized": "Ignore Shields, Wide Sweeps, Crowd Control", 
			"dodgeDistance": "1.1", 
			"dodgeSpeed": "1.1", 
			"dodgeCount": "6", 
			"stamina": "4", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "180", 
			"rightClickMovementModifier": "0.75", 
			"canWieldPrimary": ["wh_priest"], 
			"canWieldSecondary": ["wh_priest"], 
			"traitCategory": "melee", 
			"propertyCategory": "melee"
		},
		"bw_reaper": {
			"name": "Ensorcelled Reaper", 
			"flavorText": "", 
			"description": "weapon_keyword_wide_sweeps, weapon_keyword_crowd_control, weapon_keyword_damage_over_time", 
			"tooltipLocalized": "Wide Sweeps, Crowd Control, Damage over Time", 
			"dodgeDistance": "1.0", 
			"dodgeSpeed": "1.0", 
			"dodgeCount": "2", 
			"stamina": "6", 
			"blockInnerCost": "0.5", 
			"blockOuterCost": "2", 
			"blockAngle": "90", 
			"rightClickMovementModifier": "0.75", 
			"canWieldPrimary": ["bw_scholar", "bw_adept", "bw_unchained", "bw_necro"], 
			"canWieldSecondary": [], 
			"traitCategory": "melee", 
			"propertyCategory": "melee"
		},
		"bw_soulsteal": {
			"name": "Soulstealer Staff", 
			"flavorText": "", 
			"description": "weapon_keyword_sniper, weapon_keyword_crowd_control, weapon_keyword_damage_over_time", 
			"tooltipLocalized": "Sniper, Crowd Control, Damage over Time", 
			"dodgeDistance": "1", 
			"dodgeSpeed": "1", 
			"dodgeCount": "2", 
			"stamina": "nil", 
			"blockInnerCost": "nil", 
			"blockOuterCost": "nil", 
			"blockAngle": "nil", 
			"rightClickMovementModifier": "0.75", 
			"canWieldPrimary": [], 
			"canWieldSecondary": ["bw_necro"], 
			"traitCategory": "ranged_heat", 
			"propertyCategory": "ranged"
		}
}