export const roleData = [
    { id: 1, name: 'Solo' },
    { id: 2, name: 'DPS' },
    { id: 3, name: 'Tank' },
    { id: 4, name: 'CC' },
    { id: 5, name: 'Support' },
    { id: 6, name: 'Frontliner' },
    { id: 7, name: 'Backliner' },
    { id: 8, name: 'Horde Killer' },
    { id: 9, name: 'Boss Killer' },
    { id: 10, name: 'Elite Killer' },
    { id: 11, name: 'Special Killer' }
];